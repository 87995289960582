.gradientText {
    background: $gradientText;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}

.tdButton {
    background: transparent;
    border: 1px solid $orange;
    border-radius: 0;
    font-size: 14px;
    font-family: $headingFont;
    color: $orange;
    padding: .7rem 1rem;
    transition: .6s;
    @media only screen and (max-width: $laptop) {
        padding: .5rem .8rem;
        font-size: 12px;
    }
    @media only screen and (max-width: $mobile) {
        padding: 1rem;
        font-size: 16px;
    }
    &:hover {
        color: white;
        background-color: $orange;
        border: 1px solid $orange;
    }
    &:active {
        color: white !important;
        background-color: $orange !important;
        border: 1px solid $orange !important;
    }
    &:focus {
        color: white;
        background-color: $orange;
        border: 1px solid $orange;
    }
}

.tdButtonGradient {
    background: linear-gradient(0deg, rgba(255,197,130,1) 0%, rgba(252,146,22,1) 100%);
    width: fit-content;
color: white !important;
    border-radius: 0;
    border: 0;
    font-size: 14px;
    font-family: $headingFont;
    color: $orange;
    padding: .7rem 1rem;
    &:hover {
        background: linear-gradient(0deg, rgba(255,197,130,1) 0%, rgba(252,146,22,1) 100%);
        width: fit-content;
    color: white !important;
    }
    &:active {
        background: linear-gradient(0deg, rgba(255,197,130,1) 0%, rgba(252,146,22,1) 100%);
        width: fit-content;
    color: white !important;
    }
    &:focus {
        background: linear-gradient(0deg, rgba(255,197,130,1) 0%, rgba(252,146,22,1) 100%);
        width: fit-content;
    color: white !important;
    }
}

.form-control {
    font-family: $textFont;
    margin-bottom: 1.5rem;
}

.form-select {
    font-family: $textFont;
    margin-bottom: 1.5rem;
}


.form-label {
    color: white;
    font-family: $headingFont;
}

.fit-content {
    width: fit-content;
}

.form-bg {
    background-color: $cardGrey;
}

.form-check-label {
    color: white;
    font-family: $textFont;
}

.form-check-input:checked {
    background-color: $orange;
    border-color: $orange;
}

.font-bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}


.pl {
    padding-left: 2rem;
}

.pr {
    padding-right: 2rem;
}

.offcanvas {
    @media only screen and (max-width: $tablet) {
        background-color: $cardGrey;
        width: 100% !important;
    }
}

.page {
    position: relative;
    &-btt {
        background: $gradientText;
        padding: .5rem;
        border-radius: 50rem;
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        cursor: pointer;
        z-index: 998;
    }
}

.icon-link {
    border-bottom: 0;
}

.w-80 {
    width: 80%;
}
.w-90 {
    width: 100%;
    @media only screen and (max-width: $mobile) {
        width: 100% !important;
    }
}

.row {
    @media only screen and (max-width: $mobile) {
        display: flex;
        flex-direction: column;
    }
}

.center {
    @media only screen and (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.tab-center {
    @media only screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.contact-row {
    @media only screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
    }
}