.casePreview {
    display: flex;
    @media only screen and (max-width: $mobile) {
        flex-direction: column;
    }
    &-item {
        position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 20%;
            background-position: center center !important;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover !important;
            @media only screen and (max-width: $mobile) {
                padding-bottom: 100%;
            }
        &-content {
            background-color: rgba(20, 20, 20, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: .4s;
            &-text {
                opacity: 0;
                transition: .4s;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media only screen and (max-width: $mobile) {
                    opacity: 1;
                }
                p {
                    margin-bottom: 1rem;
                    font-weight: 500;
                    font-size: 25px;
                    font-family: $headingFont;
                }
            }
            &:hover {
                background-color: rgba(20, 20, 20, 0.8);
            }
        }
    }
}

.casePreview-item-content:hover .casePreview-item-content-text{
    opacity: 1;
}