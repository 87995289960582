.textImage {
    @media only screen and (max-width: $tablet) {
        flex-direction: column-reverse !important;
    }
    &-bg {
        background: url("../Images/work/newBg.png") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        position: relative;
        -o-background-size: cover;
        background-size: cover;
        @media only screen and (max-width: $mobile) {
            background: url("../Images/work/newBg.png") center;
            background-size: cover;
        }
    }
    display: flex;
    @media only screen and (max-width: $mobile) {
        flex-direction: column-reverse;
    }
    &-text {
        padding: 5rem;
        padding-left: 0;
        @media only screen and (max-width: $tablet) {
            width: 100%;
        }
        @media only screen and (max-width: $tablet) {
            text-align: center;
            padding-right: 0;
        }
        @media only screen and (max-width: $mobile) {
            padding: 2rem 1rem;
            padding-top: 1rem;
        }
    }

    &-image {
        padding: 3rem;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: $laptop) {
            padding: 0;
        }
        @media only screen and (max-width: $tablet) {
            width: 100%;
            padding: 10rem;
            padding-bottom: 0;
            padding-top: 2rem;
        }
        @media only screen and (max-width: $mobile) {
            padding: 2rem 1rem;
            padding-bottom: 1rem;
        }
        &__item {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-position: center center !important;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover !important;
        }
    }
}

.textImageReverse {
    &-color {
        background-color: $cardGrey;
    }
    display: flex;
    flex-direction: row-reverse;
    @media only screen and (max-width: $tablet) {
        flex-direction: column-reverse !important;
    }
    @media only screen and (max-width: $mobile) {
        flex-direction: column-reverse;
    }
    &-text {
        padding: 5rem;
        padding-right: 0;
        @media only screen and (max-width: $tablet) {
            width: 100%;
            padding-left: 0;
            text-align: center;
        }
        @media only screen and (max-width: $mobile) {
            padding: 2rem 1rem;
            padding-top: 1rem;
        }
    }

    &-image {
        padding: 3rem;
        padding-left: 0;
        background-color: $cardGrey;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: $tablet) {
            width: 100%;
            padding: 10rem;
            padding-bottom: 0;
            padding-top: 2rem;
        }
        @media only screen and (max-width: $mobile) {
            padding: 2rem 1rem;
            padding-bottom: 1rem;
        }
        
        &__item {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-position: center center !important;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover !important;
        }
    }
}