@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

$textFont: 'Montserrat', sans-serif;
$headingFont: 'Lexend', sans-serif;

$laptop: 1550px;
$tablet: 950px;
$mobile: 500px;

$orange: #FC9216;
$lightOrange: #FFC582;
$grey: #2B2B2B;
$darkGrey: #1F1F1F;
$cardGrey: #101010;

$gradientText: -webkit-linear-gradient(90deg, rgba(255, 197, 130, 1) 0%, rgba(252, 146, 22, 1) 100%);
$gradientTextAlt: -webkit-linear-gradient(270deg, rgba(255, 197, 130, 1) 0%, rgba(252, 146, 22, 1) 100%);

h1,
h2,
h3,
h4,
h5 {
    font-family: $headingFont;
    background: $gradientText;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h1 {
    font-weight: bold;
    font-size: 100px;
    @media only screen and (max-width: $laptop) {
        font-size: 60px;
    }
}

h2 {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: .5rem;
    @media only screen and (max-width: $laptop) {
        font-size: 30px;
    }
}

h3 {
    font-size: 25px;
    line-height: 1.8rem;
    font-weight: bold;
}

p,
a,
span,
li {
    font-family: $textFont;
}

li {
    color: white;
}

ul {
    list-style-type: disc;
    list-style-position: inside;
}

p {
    line-height: 1.6rem;
    font-size: 18px;
    color: white;
    @media only screen and (max-width: $laptop) {
        font-size: 16px;
    }
}

a {
    color: $orange;
    border-bottom: 1px solid $orange;
    text-decoration: none;
    font-size: 18px;
    padding-bottom: .2rem;
    @media only screen and (max-width: $laptop) {
        font-size: 14px;
    }
}

body {
    background-color: $darkGrey;
}