.HomeSlide-item {
    height: 30rem;
    position: relative;
    background-position: center center !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    &-info {
        width: 60%;
        height: 100%;
        box-sizing: border-box;
        padding: 6rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        right: 0;
        background-color: rgba(24, 24, 24, 0.8);
        @media only screen and (max-width: $mobile) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__title {
            @media only screen and (max-width: $mobile) {
                text-align: center;
                width: 100%;
            }
        }
        p {
            width: 90%;
            @media only screen and (max-width: $mobile) {
                text-align: center;
                width: 100%;
            }
        }
        @media only screen and (max-width: $mobile) {
            width: 100%;
            padding: 2rem 3rem;
        }
    }
}