.packages {
    padding-top: 3rem;
    padding-bottom: 3rem;
    &-items {
        @media only screen and (max-width: $tablet) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
        }
    }
    &-bg {
        background: url("../Images/work/newBg.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        position: relative;
        -o-background-size: cover;
        background-size: cover;
        @media only screen and (max-width: $tablet) {
            background: url("../Images/work/newBgtwo.png") center;
            background-size: contain;
        }


    }
    &-bgtwo {
        background: url("../Images/work/newBgtwo.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        position: relative;
        -o-background-size: cover;
        background-size: cover;
        @media only screen and (max-width: $tablet) {
            background: url("../Images/work/newBgtwo.png") center;
            background-size: contain;
        }
        
    }
    &-bgthree {
        background: url("../Images/work/newBgthree.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        position: relative;
        -o-background-size: cover;
        background-size: cover;
        @media only screen and (max-width: $tablet) {
            background: url("../Images/work/newBgtwo.png") center;
            background-size: contain;
        }
        
    }
    &__extra {
        text-align: center;
        color: rgb(152, 152, 152);
        margin-top: 1rem;
    }
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        &__title {
        }
        &__desc {
            text-align: center;
            width: 50rem;
            @media only screen and (max-width: $tablet) {
                width: 100%;
            }
        }
    }
}

.packagesItem {
    width: 100%;
    height: 100%;
    background-color: $cardGrey;
    border-radius: 25px;
    display: flex;
    @media only screen and (max-width: $tablet) {
        width: 60%;
    }
    @media only screen and (max-width: $mobile) {
        width: 100%;
    }
    flex-direction: column;
    &-container {
        @media only screen and (max-width: $tablet) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    @media only screen and (max-width: $mobile) {
        height: 37rem;
    }
    &-top {
        background: $gradientText;
        display: flex;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        
        svg {
            margin-bottom: 1rem;
            @media only screen and (max-width: $laptop) {
                margin-bottom: .5rem;
            }
        }
        &__title {
            font-family: $headingFont;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            margin-bottom: 1rem;
            @media only screen and (max-width: $laptop) {
                margin-bottom: 0;
                font-size: 16px;
            }
        }
        &__price {
            font-weight: bold;
            font-family: $headingFont;
            font-size: 60px;
            line-height: unset !important;
            @media only screen and (max-width: $laptop) {
                margin-bottom: 0;
                font-size: 40px;
            }
        }
    }
    &-bottom {
        padding: 3rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media only screen and (max-width: $laptop) {
            padding: 2rem;
        }
        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: .8rem;
            @media only screen and (max-width: $laptop) {
                margin-bottom: 0.3rem;
            }
            svg {
                margin-right: .5rem;
            }
        }
        &__button {
            position: absolute;
            bottom: 0;
        
        }
    }
}