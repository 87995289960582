.nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: center;
        padding: 4rem 2rem;
    }

    &__item {
        color: white !important;
        text-decoration: none;
        margin-right: 2rem;
        font-size: 14px;
        padding: 0 !important;
        border: 0 !important;
        transition: .6s;
        @media only screen and (max-width: $tablet) {
            margin-right: 0;
            font-size: 24px;
            margin-bottom: 4rem;
        }
        &:hover {
            color: $orange !important;
        }

        &--active {
            background: $gradientText;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.fixedNav {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: $cardGrey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 4rem;
    z-index: 997;
    @media only screen and (max-width: $tablet) {
        display: none;
    }
    &__logo {
        width: 15rem !important;
    }
}