.home {
    background: url("../Images/work/mainBg.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 998;
    position: relative;


    @media only screen and (max-width: $mobile) {
        background: url("../Images/work/mainBg.png") center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        :after {
            left: 0;
            right: 0
        }
    }

    &-landing {
        height: 100vh;
        box-sizing: border-box;
        padding: 2rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &-nav {
            display: flex;
            position: absolute;
            padding: 4rem;
            top: 0;
            width: 100%;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            @media only screen and (max-width: $mobile) {
                padding: 2rem 2rem;
                align-items: center;
            }

            &__logo {
                width: 3rem;
                height: auto;
            }
        }

        &-center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 5rem;
            @media only screen and (max-width: $mobile) {
                margin-top: 0;
            }
            &__logo {
                width: 40rem;
            }
            &__tagline {
                color: white;
                font-size: 30px;
                margin-bottom: .5rem;
                font-weight: 100;

                @media only screen and (max-width: $laptop) {
                    font-size: 20px;
                    margin-bottom: 0;
                }
            }

            &__digital {
                color: white;
                margin-top: -1rem;
                margin-bottom: 1rem;
                background: none !important;
                -webkit-background-clip: none;
                -webkit-text-fill-color: white;

                @media only screen and (max-width: $laptop) {
                    margin-top: -0.5rem;
                }
            }

            &-icons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 3rem;

                &__item {
                    margin-right: .5rem;
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 1rem;
        }
    }
}