.footer {
    background-color: $cardGrey;
    padding: 2rem 4rem;
    @media only screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
        div {
            margin-bottom: 1rem;
        }
    }
    &__logo {
        width: 40%;
        height: auto;
        @media only screen and (max-width: $tablet) {
            margin-bottom: 1rem;
        }
    }
    &__title {
        font-size: 20px;
        margin-bottom: 1rem;
        font-family: $headingFont;
        font-weight: bold;
    }
    &__link {
        color: white;
        border: 0;
        margin-bottom: .5rem;
        @media only screen and (max-width: $tablet) {
            font-size: 14px;
        }
    }
    &__icon {
        margin-right: .5rem;
    }
}