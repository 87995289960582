.devProcess {
    display: flex;
    flex-direction: column;
    padding: 5rem 0;
    @media only screen and (max-width: $tablet) {
        padding: 2.5rem 0;
    }
    &-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        &__desc {
            width: 50rem;
            text-align: center;
            margin-bottom: 2rem;
            @media only screen and (max-width: $tablet) {
                width: 100%;
            }
        }
    }
    &-cards {
        &-item {
            width: 90%;
            background-color: $cardGrey;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-top-left-radius: 50rem;
            border-bottom-left-radius: 50rem;
            justify-content: center;
            @media only screen and (max-width: $laptop) {
                width: 95%;
            }
            @media only screen and (max-width: $tablet) {
                flex-direction: column;
                border-radius: 2rem;
                width: 90%;
                padding: 2rem;
                margin-bottom: 2rem;
            }
            @media only screen and (max-width: $mobile) {
                flex-direction: column;
                border-radius: 2rem;
                width: 100%;
                padding: 2rem;
                margin-bottom: 2rem;
            }
            &-gold {
                padding: 3.5rem;
                background: $gradientText;
                border-radius: 50rem;
                margin-right: 1rem;
                @media only screen and (max-width: $tablet) {
                    margin-right: 0;
                    margin-bottom: 2rem;
                    padding: 3rem;
                }
            }
            &-text {
                padding-right: 1rem;
                &__title {
                    font-weight: bold;
                    margin-bottom: .5rem;
                    font-size: 18px !important;
                }
            }
        }
    }
}