.quote {
    background-position: center center !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    background-color: white;
    @media only screen and (max-width: $mobile) {
        background-attachment: scroll !important;
    }
    &-grey {
        background-color: rgba(24, 24, 24, 0.8);
        @media only screen and (max-width: $mobile) {
            padding: 2rem 0;
        }
    }
    &-container {
        @media only screen and (max-width: $tablet) {
            padding-top: 2rem;
            padding-bottom: 2rem;
         }
        &__image {
            border-radius: 50rem;
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-position: center center !important;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover !important;
            @media only screen and (max-width: $mobile) {
                width: 50%;
                padding-bottom: 50%;
            }
        }
        &-padding {
            padding: 6rem 8rem 6rem 0;

            @media only screen and (max-width: $laptop) {
            padding: 5rem 8rem 5rem 0;
            }
            @media only screen and (max-width: $tablet) {
                padding: 5rem 4rem 5rem 0;
            }
            @media only screen and (max-width: $mobile) {
                padding: 2rem 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
        }
        &-content {
            padding-left: 2rem;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @media only screen and (max-width: $mobile) {
                padding: 2rem;
            }
            &__mark {
                font-weight: bold;
                font-size: 60px;
            }
            &__text {
                font-size: 25px;
                line-height: 2rem;
                margin-bottom: 2rem;
                @media only screen and (max-width: $mobile) {
                    font-size: 20px;
                    text-align: center;
                }
            }
            &__name {
                color: $orange;
                align-self: flex-end;
                font-weight: bold;
            }
        }
    }
}