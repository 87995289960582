.smallLanding {
    background: url("../Images/work/mainBg.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    z-index: 998;
    position: relative;
    -o-background-size: cover;
    background-size: cover;
    @media only screen and (max-width: $mobile) {
        background: url("../Images/work/mainBg.png") center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        :after {
            left: 0;
            right: 0
        }
    }
    &-grey {
        height: 20rem;
        box-sizing: border-box;
        padding: 2rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: $mobile) {
            height: 15rem;
            background-size: cover;
                background-position: center center;
        }
    }
    &-nav {
        display: flex;
        position: absolute;
        padding: 4rem;
        top: 0;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: $mobile) {
            padding: 2rem 2rem;
            align-items: center;
        }
        &__logo {
            width: 13rem;
            height: auto;
            @media only screen and (max-width: $tablet) {
                width: 25rem;
            }
            @media only screen and (max-width: $mobile) {
                width: 13rem;
            }
        }
    }

    &-center {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;

        h1 {
            font-size: 40px;
            @media only screen and (max-width: $mobile) {
                text-align: center;
                font-size: 30px;
            }
        }

        &__tagline {
            color: white;
            font-size: 30px;
            margin-bottom: .5rem;
            font-weight: 100;
        }

        &__digital {
            color: white;
            margin-top: -1rem;
            margin-bottom: 1rem;
            background: none !important;
            -webkit-background-clip: none;
            -webkit-text-fill-color: white;
        }

        &-icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 3rem;

            &__item {
                margin-right: .5rem;
            }
        }
    }

    &-footer {
        position: absolute;
        bottom: 1rem;
    }
}